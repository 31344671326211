// import React, { useEffect } from "react";
// const ContactForm = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "//js.hsforms.net/forms/embed/v2.js";
//     script.setAttribute("charset", "utf-8");
//     script.setAttribute("type", "text/javascript");
//     document.body.appendChild(script);

//     script.addEventListener("load", () => {
//       window.hbspt.forms.create({
//         target: "#hs-form",
//         region: "na1",
//         portalId: "23872478",
//         formId: "6996bd50-63ca-4dd2-ab5b-8954d776fdb7",
//       });
//     });
//   }, []);

//   return <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
//     <div id="hs-form"></div>
//   </div>
// };

// export default ContactForm;



import React, { useEffect, useState } from "react";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.setAttribute("charset", "utf-8");
    script.setAttribute("type", "text/javascript");
    document.body.appendChild(script);

    script.addEventListener("load", async () => {
      await window.hbspt.forms.create({
        target: "#hs-form",
        region: "na1",
        portalId: "23872478",
        formId: "6996bd50-63ca-4dd2-ab5b-8954d776fdb7",
      });
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
      {isLoading ? (
        <div className="text-center">
          <div className="spinner border-4 border-gray-600"></div>
        </div>
      ) : (
        <div id="hs-form" className="bg-white rounded-lg p-6">
          <form className="flex flex-col">
            <label className="text-lg font-medium mb-2">
              Your Name
            </label>
            <input
              type="text"
              className="bg-gray-200 rounded-lg p-2 mb-4"
            />
            <label className="text-lg font-medium mb-2">
              Your Email
            </label>
            <input
              type="email"
              className="bg-gray-200 rounded-lg p-2 mb-4"
            />
            <label className="text-lg font-medium mb-2">
              Your Message
            </label>
            <textarea
              className="bg-gray-200 rounded-lg p-2 mb-4"
            ></textarea>
            <button className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">
              Submit
              </button>
          </form>
        </div>
      )}
    </div>
  );
};
export default ContactForm;
