import React from 'react';
import ReactDOM, { hydrateRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import RegionProvider from './RegionProvider';
import { HelmetProvider } from 'react-helmet-async';
hydrateRoot(
  document.getElementById('root'),
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <RegionProvider>
          <App />
        </RegionProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
reportWebVitals();
