/* This example requires Tailwind CSS v2.0+ */
export default function HeaddingImage({region,us,uk}) {
    return (
      <>
      {region === 'uk' ?
        <img
        className="w-full-1/2 object-cover"
        src={uk}
        alt=""
      /> :
        <img
        className="w-full-1/2 object-cover"
        src={us}
        alt=""
      />}
      </>
    )
  }