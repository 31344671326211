import React from 'react'
import ServicesGrid from '../../../components/Services/ServicesGrid'
import ServicesHero from '../../../components/HeroSection/ServicesHero'
import PageTitle from '../../../components/PageHeadding/PageTitle'
import Price from '../../../elements/Price/Price'
import Prosess  from '../../../components/Prosess/Prosess'
import Faqs2 from '../../../components/FAQS/FAQs2'
import Blogs from '../../../components/Blogs/Blogs'
import NavBar from '../../../components/NavBar/NavBar'
import Footer from '../../../components/Footer/Footer'
import SEO from '../../../elements/SEO/SEO'
const ECommerce = ({region, geo, pageid}) => {

  return (
    <>
    <SEO region={region} pageid={pageid}/>
    <NavBar region={region} geo={geo}/>
    <ServicesHero
    head={'Exceptional'}
    main={'E-Commerce Web Design'}
    paragraph={'E-commerce web development is the process of creating and maintaining an online store or marketplace to sell products or services over the internet.'}
    />
    <PageTitle title='E-Commerce Web Design'/>
    <ServicesGrid page={'ecommerce'}/>
    <Price 
    region={region}
    geo={geo}
    title={'Creating a Successful Online Store'}
    description={'Our Comprehensive E-Commerce Web Development Package offers a complete solution for creating a successful online store. From design and development to integration and ongoing support, we will work with you every step of the way to ensure your online store is user-friendly, visually appealing and optimized for conversions.'}
    page={'ecommerce'}
    />
    <Prosess/>
    <Faqs2/>
    <Blogs/>
    <Footer region={region}/>
    </>
  )
}

export default ECommerce