/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

    const ukfaqs = [
      {
        id: 1,
        question: "Why do I have to pay if WordPress is a free platform?",
        answer:
          "WordPress itself is a CMS (Content Management System) that allows you to build your own customized websites. Professional WordPress Web Designers are expert at creating websites as they have a sound grip on all the tools within them.",
      },
      {
        id: 2,
        question: "How much will an e-commerce website ​​​​​cost me?",
        answer:
          "At Ehmiat, our website development and design services starts from as low as £349 only. The price may vary according to the project and customizations you need.​",
      },
      {
        id: 3,
        question: "Why I need monthly website maintenance services?",
        answer:
          "Websites require regular recovery of data, optimization, bug removal and upgrades, which will be taken care of by the best developers at Ehmiat making your website error-free.",
      },
      {
        id: 4,
        question: "What services are included in web maintenance services?",
        answer:
          "Our web maintenance services include back-up recovery, bug removal, speed optimization, mobile optimization, tracking and analytics, accessibility and much more. Please click the link for detailed information about the web maintenance package. ",
      },
      {
        id: 5,
        question: "How can I connect with the Ehmiat team?",
        answer:
          "You can mail us on official emails, social platforms or you may fill out the form, so our team will contact you on your given email.",
      },
    ]
    const usfaqs = [
      {
        id: 1,
        question: "Why do I have to pay if WordPress is a free platform?",
        answer:
          "WordPress itself is a CMS (Content Management System) that allows you to build your own customized websites. Professional WordPress Web Designers are expert at creating websites as they have a sound grip on all the tools within them.",
      },
      {
        id: 2,
        question: "How much will an e-commerce website ​​​​​cost me?",
        answer:
          "At Ehmiat, our website development and design services starts from as low as $499 only. The price may vary according to the project and customizations you need.​",
      },
      {
        id: 3,
        question: "Why I need monthly website maintenance services?",
        answer:
          "Websites require regular recovery of data, optimization, bug removal and upgrades, which will be taken care of by the best developers at Ehmiat making your website error-free.",
      },
      {
        id: 4,
        question: "What services are included in web maintenance services?",
        answer:
          "Our web maintenance services include back-up recovery, bug removal, speed optimization, mobile optimization, tracking and analytics, accessibility and much more. Please click the link for detailed information about the web maintenance package. ",
      },
    ]
  // More questions...

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Faqs2({region}) {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {region === 'uk' ?
            ukfaqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )) : 
            usfaqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))
            }
          </dl>
        </div>
      </div>
    </div>
  )
}