import React, { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
// import RegionChange from '../../elements/Modal/RegionChange'
import CookieNotice from '../CookieNotice/CookieNotice'
import LogoW from '../../media/Logo/Logow.svg'
import Logo from '../../media/Logo/Logo.png'
import axios from 'axios'
// import RegionStatus from './RegionStatus'
const navigation = [
  { title: 'Home', slug: '' },
  { title: 'Landging Page Design', slug: 'landing-page-design' },
  { title: 'E-Commerce Web Design', slug: 'ecommerce-web-design' },
  { title: 'Website Maintenance', slug: 'website-maintenance' },
]
const NavBar = ({ region, geo }) => {
  const [MenuOpen, setMenuOpen] = useState('')
  const menuopen = () => {
    setMenuOpen('');
  };
  const menuclose = () => {
    setMenuOpen('hidden');
  };
  // region status
  const [country, setCountry] = useState(null);
  const [showRS, setShowRS] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=7952c4c8f99d444cb7537c79d67aaacb');
        setCountry(response.data.country_name);
        const europeanCountries = ['Albania', 'Andorra', 'Armenia', 'Austria', 'Azerbaijan', 'Belarus', 'Belgium', 'Bosnia and Herzegovina', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Georgia', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy', 'Kazakhstan', 'Kosovo', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Moldova', 'Monaco', 'Montenegro', 'Netherlands', 'North Macedonia', 'Norway', 'Poland', 'Portugal', 'Romania', 'Russia', 'San Marino', 'Serbia', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'Turkey', 'Ukraine', 'United Kingdom', 'Vatican City'];
        if (country === 'United States' || country === 'United Kingdom' || country === 'Canada' || country === 'Australia' || country === 'New Zealand' || europeanCountries.includes(country)) {
          setShowRS(false);
        } else {
          setShowRS(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {/* <RegionStatus geo={geo}/> */}
      <CookieNotice region={region} />
      <Popover as="header" className="relative">
        {/* {region === "us" || "gb" & geo === "Pakistan" ? `Find content specific to your location by selecting your data.country_name Pakistan` : null } */}
        {/* {region === "us" & geo === "United Kingdom" ?
        <RegionChange region={"uk"}/> : null}
      {region === "gb" & geo === "United States" ?
        <RegionChange region={"us"}/> : null} */}
        <div className="bg-black pt-6 pb-6 	">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to={`/${region}`}>
                  <img
                    className="h-8 w-auto sm:h-8"
                    src={LogoW} alt="Logo" />
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-neutral-900 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon onClick={menuopen} className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                  <Link
                    key={item.title}
                    to={`/${region}/${item.slug}`}
                    className="text-base font-medium text-white"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6">
              {/* <a href="#1" className="text-base font-medium text-white hover:text-gray-300">
              Log in
            </a> */}
              <Link
                to={`/${region}/contact`}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md  bg-white hover:bg-neutral-50"
              >
                Contact
              </Link>
            </div>
          </nav>
        </div>
        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className={`${MenuOpen} absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden`}>
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={Logo} alt="Logo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className=" rounded-md p-2 inline-flex items-center justify-center text-black  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      onClick={menuclose}
                      key={item.title}
                      to={`/${region}/${item.slug}`}
                      className="block px-3 py-2 rounded-md text-base font-medium text-black hover:bg-gray-50"
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <Link
                    to={`/${region}/contact`}
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-black text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                  >
                    Contact
                  </Link>
                </div>
                {/* <div className="mt-6 px-5">
                <p className="text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <a href="#1" className="text-gray-900 hover:underline">
                    Login
                  </a>
                </p>
              </div> */}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      {/* { */}
      {
        showRS
          ? (
            <div className="px-4 py-3 leading-normal text-green-700 bg-green-100 rounded-lg" role="alert">
              <p className="font-bold">Welcome to Ehmiat <i>{country}</i></p>
              {/* <p>Sorry! sadf.com is not available in {country}. for general questions hello@ehmiat.com</p> */}
            </div>
          )
          : null
      }
      {/* } */}
    </>
  )
}

export default NavBar