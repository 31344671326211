import React from 'react'
import ServicesGrid from '../../../components/Services/ServicesGrid'
import ServicesHero from '../../../components/HeroSection/ServicesHero'
import PageTitle from '../../../components/PageHeadding/PageTitle'
import Price from '../../../elements/Price/Price'
import Prosess  from '../../../components/Prosess/Prosess'
import Faqs2 from '../../../components/FAQS/FAQs2'
import Blogs from '../../../components/Blogs/Blogs'
import NavBar from '../../../components/NavBar/NavBar'
import Footer from '../../../components/Footer/Footer'
import SEO from '../../../elements/SEO/SEO'
const Maintenance = ({region, geo, pageid}) => {

  return (
    <>
    <SEO region={region} pageid={pageid}/>
    <NavBar region={region} geo={geo}/>
    <ServicesHero
    head={'Exceptional'}
    main={'Website Maintenance'}
    paragraph={'Websites require constant runtime changes for mature functioning. That’s why we keep you covered with our monthly maintenance services to keep your websites running smoothly.'}
    />
    <PageTitle title='Web Maintenance'/>
    <ServicesGrid page={'maintenance'}/>
    <Price 
    region={region}
    geo={geo}
    title={'Keep Your Site Running Smoothly'}
    description={"Our website maintenance package includes regular updates, backups, security monitoring, and technical support to ensure your website is always running at its best. With our team of experts on hand, you can rest easy knowing that any issues or concerns will be promptly addressed, leaving you free to focus on running your business. Don't let technical problems slow you down - choose our website maintenance package today!"}
    page={'maintenance'}
    />
    <Prosess/>
    <Faqs2/>
    <Blogs/>
    <Footer region={region}/>
    </>
  )
}

export default Maintenance