export default function Example({ page, props }) {
  const landingpage = [
    {
      name: "Fresh Content",
      description: "Our team of experienced writers will create unique, engaging content for your landing page that is tailored to your target audience. We'll conduct industry research to ensure that your messaging is on-point and relevant to your visitors."
    },
    {
      name: "Industry Research",
      description: "We'll conduct extensive research on your industry to ensure that your landing page is optimized for your target audience. This includes identifying key trends, competitors, and pain points that your visitors may be experiencing."
    },
    {
      name: "Clean, Responsive Design",
      description: "Our team of designers will create a clean, modern design that is optimized for conversions. We'll ensure that your landing page is responsive and looks great on all devices, including desktops, laptops, tablets, and smartphones."
    },
    {
      name: "Mobile Friendly",
      description: "With more and more users accessing the internet on their mobile devices, it's essential that your landing page is mobile-friendly. We'll ensure that your landing page is optimized for small screens and touch-based navigation."
    },
    {
      name: "Optimized for SEO",
      description: "Our team will optimize your landing page for search engines, including keyword research, meta tags, and other on-page SEO techniques. This will help your page rank higher in search results and drive more traffic to your site."
    },
    {
      name: "Gear for Conversion",
      description: "Our landing page design services will focus on conversion optimization, including clear calls-to-action, easy navigation, and compelling visuals. We'll work with you to identify and target specific goals, such as lead generation or sales."
    },
    {
      name: "3 Day Turnaround",
      description: "We understand the importance of getting your landing page up and running quickly. That's why we offer a 3-day turnaround for our landing page design services, ensuring that you can start seeing results as soon as possible."
    }
  ]
  const ecommerce = [
    {
      name: "Main Pages",
      description: "Our team will design and develop the main pages of your e-commerce website, including the homepage, about us, contact us, and other important pages."
    },
    {
      name: "Product Pages",
      description: "We'll create custom product pages that showcase your products in an attractive and user-friendly way, including product descriptions, images, and reviews."
    },
    {
      name: "Perfect for Businesses",
      description: "Our e-commerce web development services are perfect for businesses of all sizes, whether you're just starting out or are an established company."
    },
    {
      name: "Theme Based Design",
      description: "We'll work with you to create a theme-based design that aligns with your brand and appeals to your target audience."
    },
    {
      name: "Admin Dashboard Area",
      description: "We'll create an admin dashboard area that allows you to manage your e-commerce website easily, including adding products, processing orders, and more."
    },
    {
      name: "User Dashboard Area",
      description: "We'll also create a user dashboard area that allows your customers to track their orders, manage their account information, and more."
    },
    {
      name: "Payment Integration",
      description: "We'll integrate a variety of payment options into your e-commerce website, including credit card, PayPal, and other popular payment methods."
    },
    {
      name: "Content Management System",
      description: "Our e-commerce web development services include a content management system that allows you to easily update and manage the content on your website."
    },
    {
      name: "Original Content",
      description: "Our team will write original content for your website, including product descriptions, blog posts, and other important pages."
    },
    {
      name: "Multi-Currency",
      description: "We'll include a multi-currency feature that allows customers to view prices in their local currency."
    },
    {
      name: "Discount Coupon Code Module",
      description: "We'll include a discount coupon code module that allows you to easily create and manage coupons for your customers."
    },
    {
      name: "Sliders",
      description: "We'll include sliders on your homepage and other pages to showcase your products and promotions."
    },
    {
      name: "Responsive Design",
      description: "Our e-commerce web development services include a responsive design that looks great on all devices, including desktops, laptops, tablets, and smartphones."
    },
    {
      name: "Social Media Integration",
      description: "We'll integrate your e-commerce website with popular social media platforms, including Facebook, Twitter, and Instagram."
    },
    {
      name: "SEO Features",
      description: "We'll include a variety of SEO features to help your e-commerce website rank higher in search results and drive more traffic to your site."
    },
    {
      name: "Blog Setup",
      description: "We'll set up a blog for your e-commerce website, allowing you to easily create and publish blog posts that will help drive traffic to your site."
    },
    {
      name: "Contact Forms Included",
      description: "We'll include contact forms on your website, allowing customers to easily contact you with any questions or concerns."
    },
    {
      name: "Multiple Revisions",
      description: "We understand that getting the perfect design and functionality for your e-commerce website is important. That's why we offer multiple revisions, ensuring that you're completely satisfied with the final product."
    }
  ]
  const maintenance = [
    {
      name: "Upto 12 Support Hours",
      description: "Our team will provide up to 12 support hours per month to ensure that your website is running smoothly and any issues are addressed in a timely manner."
    },
    {
      name: "Free Task Manager Access",
      description: "You'll have access to our task manager, allowing you to easily submit and track maintenance requests."
    },
    {
      name: "0-3 days Turnaround Time",
      description: "We understand the importance of keeping your website updated and running smoothly. That's why we offer a 0-3 day turnaround time for our web maintenance services."
    },
    {
      name: "Image Edits and Uploading to website",
      description: "Our team will edit and upload images to your website, ensuring that they are optimized for web and look great on all devices."
    },
    {
      name: "Existing Content Modifications",
      description: "We'll make any necessary modifications to the existing content on your website, including updates and revisions."
    },
    {
      name: "Adding, Removing & Updating website links",
      description: "We'll add, remove, and update links on your website as necessary, ensuring that your visitors can easily navigate your site."
    },
    {
      name: "SSL Renewal Check",
      description: "We'll check and renew your SSL certificate, ensuring that your website is secure and visitors' personal information is protected."
    },
    {
      name: "Domain Renewal Check",
      description: "We'll check and renew your domain, ensuring that your website remains active and accessible to your visitors."
    },
    {
      name: "HTML Sitemap Creation & Uploading",
      description: "We'll create and upload an HTML sitemap to your website, making it easier for search engines to crawl and index your site."
    },
    {
      name: "Website Speed Optimization",
      description: "Our team will optimize your website's speed, ensuring that your pages load quickly and visitors have a positive experience."
    },
    {
      name: "Email/Domain/Hosting Support",
      description: "We'll provide support for your email, domain, and hosting, ensuring that everything is running smoothly and any issues are resolved in a timely manner."
    },
    {
      name: "3rd Party Web Hosting Help",
      description: "We'll provide support for any 3rd party web hosting services you're using, including assistance with setup and troubleshooting."
    },
    {
      name: "Daily Backup",
      description: "We'll perform daily backups of your website, ensuring that your data is safe and can be easily restored in case of an emergency."
    },
    {
      name: "Consulting Requests",
      description: "Our team is available to consult with you on any web maintenance related topics, including best practices, troubleshooting, and more."
    },
    {
      name: "Form Creation",
      description: "We'll create custom forms for your website, including contact forms, registration forms, and more."
    },
    {
      name: "PHP Scripting Help",
      description: "Our team is experienced in PHP scripting and can assist with any issues or modifications needed on your website."
    },
    {
      name: "CMS / WordPress Support",
      description: "We'll provide support for your CMS or WordPress website, including troubleshooting, plugin and theme updates, and more."
    },
    {
      name: "Server Support",
      description: "We'll provide support for your server, including monitoring, troubleshooting, and maintenance to ensure that your website is running smoothly."
    },
    {
      name: "E-commerce Site Support",
      description: "We'll provide support for your e-commerce website, including troubleshooting, plugin and theme updates, and more."
    },
    {
      name: "On-Site SEO",
      description: "Our team will perform on-site SEO optimization, including meta tag updates, keyword research, and more, to help improve your website's search engine rankings."
    },
    {
      name: "Plugins and Theme Version Update",
      description: "We'll update your website's plugins and theme to the latest version to ensure compatibility and security."
    },
    {
      name: "Extension Installation & Modification",
      description: "Our team can install and modify extensions on your website to add new functionality and improve the user experience."
    }
  ]
  return (
    <div className="bg-black">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray tracking-tight">All-in-one platform</h2>
        {/* <p className="mt-4 max-w-3xl text-lg text-gray">
          Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
        </p> */}
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">

          {
            page === "landingpage" ? landingpage.map((feature) => (
              <div key={feature.name}>
                {/* <div>
                  <span className="flex items-center justify-center h-12 w-12 rounded-md bg-gray2 bg-opacity-30">
                    <div className="h-8 w-8 text-white" aria-hidden="true">
                      <svg
                        viewBox="0 0 512 512"
                        fill="currentColor"
                        height="2em"
                        width="2em"
                        {...props}
                      >
                        <path d={feature.icon} />
                      </svg>
                    </div>
                  </span>
                </div> */}
                <div className="mt-6">
                  <h3 className="text-2xl font-medium text-white">{feature.name}</h3>
                  <p className="mt-2 text-base text-white">{feature.description}</p>
                </div>
              </div>
            )) :
              page === "ecommerce" ? ecommerce.map((feature) => (
                <div key={feature.name}>
                  {/* <div>
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-gray2 bg-opacity-30">
                      <div className="h-8 w-8 text-white" aria-hidden="true">
                        <svg
                          viewBox="0 0 512 512"
                          fill="currentColor"
                          height="2em"
                          width="2em"
                          {...props}
                        >
                          <path d={feature.icon} />
                        </svg>
                      </div>
                    </span>
                  </div> */}
                  <div className="mt-6">
                    <h3 className="text-2xl font-medium text-white">{feature.name}</h3>
                    <p className="mt-2 text-base text-white">{feature.description}</p>
                  </div>
                </div>
              )) :
                page === "maintenance" ? maintenance.map((feature) => (
                  <div key={feature.name}>
                    {/* <div>
                      <span className="flex items-center justify-center h-12 w-12 rounded-md bg-gray2 bg-opacity-30">
                        <div className="h-8 w-8 text-white" aria-hidden="true">
                          <svg
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            height="2em"
                            width="2em"
                            {...props}
                          >
                            <path d={feature.icon} />
                          </svg>
                        </div>
                      </span>
                    </div> */}
                    <div className="mt-6">
                      <h3 className="text-2xl font-medium text-white">{feature.name}</h3>
                      <p className="mt-2 text-base text-white">{feature.description}</p>
                    </div>
                  </div>
                )) : null
          }
        </div>
      </div>
    </div>
  )
}