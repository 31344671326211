import React from 'react'
import PageTitle from '../../components/PageHeadding/PageTitle'
import HeaddingImage from '../../components/PageHeadding/HeaddingImage'
import Faqs2 from '../../components/FAQS/FAQs2'
import NavBar from '../../components/NavBar/NavBar'
import Footer from '../../components/Footer/Footer'
import SEO from '../../elements/SEO/SEO'
// import Testimonial from '../../components/Testimonial/Testimonial'
const About = ({region, geo, pageid}) => {

  return (
    <>
    <SEO region={region} pageid={pageid}/>
            <NavBar region={region} geo={geo} />
            <PageTitle title={"About Ehmiat"} />
            <HeaddingImage />
            <div className="bg-gray-100">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="relative bg-white shadow-xl">
                        <div className="py-16 bg-white">
                            <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                                <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                                    <div className="md:5/12 lg:w-5/12">
                                        <img src="https://tailus.io/sources/blocks/left-image/preview/images/startup.png" alt="gj" loading="lazy" width height />
                                    </div>
                                    <div className="md:7/12 lg:w-6/12">
                                        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl">Our Company</h2>
                                        <p className="mt-6 text-gray-600">Since 2020, Ehmiat was developed with the intent of delivering the best value to anyone looking to start, grow, or enhance a business, especially small and medium scale ventures. With a team of qualified, creative and smart brains, we’ve created masterpiece websites that always bring the best results to our customers and give their business an online identity with an income boost. We carefully listen to our customer’s requirements and also make sure that we carefully design a website that best portrays your brand image.
                                            Ehmiat means the “Ultimate Significance” and “Importance”, as we always make sure that every client gets the value for money service along with a reliable experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                            <div className="relative max-w-base mx-auto">
                                <div className='prose prose-slate mx-auto lg:prose-lg'>
                                    <h2 className="text-2xl text-gray-900 font-bold md:text-4xl">History</h2>
                                    <p>During the Covid-19, we all had a tough time, due to the collapsed economy and un-bearable market conditions. The same concerns led to the establishment of Ehmiat, but we discovered an effective method of making money through internet sales. We learned that businesses can be operated online since major sectors swiftly moved their operations online and because we had successfully conducted our own trials, we were confident in the outcomes. As a result, we took the proper action that was required at the time. We assisted small and medium-sized enterprises in creating an internet presence so they could outlive their competitors. Since that time, we have helped businesses and promoted e-commerce to enable rapid company growth. Even after the Covid-19, companies are still able to reap the rewards in the form of a supplementary revenue stream from sales made on e-commerce websites.</p>
                                </div>
                            </div>
                        </div>
                        {/* <Testimonial /> */}
                    </div>
                    <Faqs2 region={region}/>
                </div>
            </div>
            <Footer region={region} />
        </>
    )
}

export default About