import React from 'react'
import ServicesGrid from '../../../components/Services/ServicesGrid'
import ServicesHero from '../../../components/HeroSection/ServicesHero'
import PageTitle from '../../../components/PageHeadding/PageTitle'
import Price from '../../../elements/Price/Price'
import Prosess  from '../../../components/Prosess/Prosess'
import Faqs2 from '../../../components/FAQS/FAQs2'
import Blogs from '../../../components/Blogs/Blogs'
import NavBar from '../../../components/NavBar/NavBar'
import Footer from '../../../components/Footer/Footer'

import SEO from '../../../elements/SEO/SEO'
const LandingPage = ({region, geo, pageid}) => {
  return (
    <>
    <SEO region={region} pageid={pageid}/>
    <NavBar region={region} geo={geo}/>
    <ServicesHero
    head={'Exceptional'}
    main={'Landing Page Design'}
    paragraph={'Landing pages can also be referred to as single-page websites that are alternatives if you are just starting a venture and trying to keep you within a budget.'}
    />
    <PageTitle title='Landing Page Design'/>
    <ServicesGrid page={'landingpage'}/>
    <Price 
    region={region}
    geo={geo}
    title={'Designing Your Perfect Landing Page'}
    description={'Our Designing Your Perfect Landing Page package includes everything you need to create a visually stunning and highly effective landing page for your website.'}
    page={'landingpage'}
    />
    <Prosess/>
    <Faqs2/>
    <Blogs/>
    <Footer region={region}/>
    </>
  )
}

export default LandingPage