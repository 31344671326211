import HomeSEO from '../../elements/SEO/HomeSEO';
import React from 'react';
import Blogs from '../../components/Blogs/Blogs';
import CTA from '../../components/CTA/CTA';
import FAQs from '../../components/FAQS/FAQs';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import Packages from '../../components/Packages/Packages';
import Prosess from '../../components/Prosess/Prosess';
import Services from '../../components/Services/Services';
import HeroSection from './HeroSection';
// import {  } from 'react-helmet-async';
const HomePage = ({ region, geo, pageid }) => {
  const seouk =
  {
    title: "ECommerce website development company in London | Ehmiat (UK)",
    description: "Transform your online business with expert ecommerce website development from London's top company. "
  }
  const seous =
  {
    title: "Ecommerce web development services in New York | Ehmiat (US)",
    description: "Get top-notch ecommerce website development services from the leading ecommerce development company in New York."
  }
  return (
    <>
      <html lang={`en_${region}`} >
        <head>
          <title>{'Ecommerce web development services in New York | Ehmiat (US)'}</title>
          <meta name="description" content='Get top-notch ecommerce website development services from the leading ecommerce development company in New York.' />
          <link rel='canonical' href={`/us/`} />
          <meta name="robots" content="index, follow" />
          <meta name="robots" content="max-snippet:-1" />
          <meta name="robots" content="max-image-preview:large" />
          <meta name="robots" content="max-video-preview:-1" />
          <meta property="og:locale" content='en_US' />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={seous.title} />
          <meta property="og:description" content={seous.description} />
          <meta property="og:url" content={`https://www.ehmiat.com/us/`} />
          <meta property="og:image" content='https://api.ehmiat.com/wp-content/uploads/2023/01/logo.png' />
          <meta property="og:site_name" content="Ehmiat (United States)" />
          <meta name="twitter:card" content="summary_large_image" />
      </head>
      <body>
        <div className="bg-white">
          <div className=" overflow-hidden">
            <main>
              <NavBar region={region} geo={geo} />
              <HeroSection region={region} />
              <Services region={region} />
              <Prosess region={region} />
              {geo === 'Israil' ? null : (
                <Packages region={region} />
              )}
              <Blogs region={region} />
              <CTA region={region} />
              <FAQs region={region} />
              <Footer region={region} />
            </main>
          </div>
        </div>
      </body>
    </html>
    </>
  );
};


export default HomePage;
