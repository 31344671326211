import React from 'react'
// title={''}
// subtitle={''}
// discription={''}
const Container2 = ({title, subtitle, discription, bg, children}) => {
    return (
        <div className={`relative bg-${bg} py-16 sm:py-24 lg:py-3'`}>
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-dark-gray uppercase">{title}</h2>
                <p className="mt-2 text-3xl font-extrabold text-off-white tracking-tight sm:text-4xl">
                    {subtitle}
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    {discription}
                </p>
                <div className="mt-12">
                {children}
                </div>
            </div>
        </div>
    )
}

export default Container2