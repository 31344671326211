import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import PageTitle from "../../components/PageHeadding/PageTitle";
import SEO from "../../elements/SEO/SEO";
const PageDetail = ({region, geo, pageid}) => {
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    useEffect(() => {
        axios.get(`https://api.ehmiat.com/wp-json/${region}/pages/${pageid}`)
            .then(res => {
                setTitle(res.data.title.rendered);
                setContent(res.data.content.rendered);

     
            })
            .catch(err => console.log(err))
    }, [pageid, region])
    return (
      <>
      <SEO region={region} pageid={pageid}/>
            <NavBar region={region} geo={geo}/>
            <PageTitle title={title} />
            <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
                <div className="relative max-w-base mx-auto">
                    <div className='prose prose-slate mx-auto lg:prose-lg' dangerouslySetInnerHTML={{__html: content}}>
                    </div>
                </div>
            </div>
            <Footer region={region}/>
        </>
    )
}


export default PageDetail    