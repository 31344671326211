/* This example requires Tailwind CSS v2.0+ */
const uk = {
    title: "Creating a Professional and Attractive Website",
    subTitle: "Our Web Design Process",
    description: "Our team of experts follows a structured process to design and develop a professional and attractive website that meets your business goals and resonates with your target audience. From planning and strategy to testing and deployment, we ensure that your website is designed to perfection."
  }
const us = {
    title: "Creating a Professional and Attractive Website",
    subTitle: "Our Web Design Process",
    description: "Our team of experts follows a structured process to design and develop a professional and attractive website that meets your business goals and resonates with your target audience. From planning and strategy to testing and deployment, we ensure that your website is designed to perfection."
  }
const uklist = [
  { id: 1, name: 'Planning and Strategy', discription: 'Identify target audience, goals, and website features.' },
  { id: 2, name: 'Design and Development', discription: 'Create layout, visual design, and implement functionality.' },
  { id: 3, name: 'Content and SEO', discription: 'Add content and optimize for search engines.' },
  { id: 4, name: 'Testing and Deployment', discription: 'Test website and deploy live.' },
  // { id: 5, name: 'Maintenance and Updation', discription: 'Keep website updated and secure.' },
]
const uslist = [
  { id: 1, name: 'Planning and Strategy', discription: 'Identify target audience, goals, and website features.' },
  { id: 2, name: 'Design and Development', discription: 'Create layout, visual design, and implement functionality.' },
  { id: 3, name: 'Content and SEO', discription: 'Add content and optimize for search engines.' },
  { id: 4, name: 'Testing and Deployment', discription: 'Test website and deploy live.' },
  // { id: 5, name: 'Maintenance and Updation', discription: 'Keep website updated and secure.' },
]
export default function Prosess(region) {
  return (
    <div className="relative bg-black">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-80 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
              alt="People working on laptops"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="my-10 p-4 xl:bg-gray relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold text-white tracking-wide uppercase xl:text-black">{region === 'uk' ? uk.title : us.title}</h2>
          <p className="mt-3 text-3xl font-extrabold text-white xl:text-black">
          {region === 'uk' ? uk.subTitle : us.subTitle}
          </p>
          <p className="mt-5 text-lg text-white xl:text-black">
          {region === 'uk' ? uk.description : us.description}
          </p>
          <div className=" mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
           {region === 'uk' ? 
          uklist.map((item) => (
            <p key={item.id} className="bg-white p-4 border border-gray2">
              <span className="block text-md font-bold text-black">
                <span className="flex items-center justify-center w-6 h-6 mr-2 mb-2 font-medium text-white rounded-full bg-black shadow">{item.id}</span>
                {item.name}
              </span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-black">{item.discription}</span>
              </span>
            </p>
          )) : 
          uslist.map((item) => (
            <p key={item.id} className="bg-white p-4 border border-gray2">
              <span className="block text-md font-bold text-black">
                <span className="flex items-center justify-center w-6 h-6 mr-2 mb-2 font-medium text-white rounded-full bg-black shadow">{item.id}</span>
                {item.name}
              </span>
              <span className="mt-1 block text-base text-gray-300">
                <span className="font-medium text-black">{item.discription}</span>
              </span>
            </p>
          )) 
          }
          </div>
        </div>
      </div>
    </div>
  )
}