import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
const CookieNotice = ({region}) => {
    const [isAccepted, setIsAccepted] = useState(Cookies.get('cn') || false);

    const handleAccept = () => {
        setIsAccepted(true);
        Cookies.set('cn', true, { expires: 30 });
    };
    return (
        <div>
            {!isAccepted &&
                <div className="z-10  fixed bottom-0 sahdow left-0 right-0 bg-gray2">
                    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="pr-16 sm:text-center sm:px-16">
                            <p className="font-medium text-black">
                                {/* <span className="md:hidden">“This website collects cookies to deliver better user experience”</span> */}
                                <span className="">“This website collects cookies to deliver better user experience” {" "}<Link to={`/${region}/privacy-policy`}>Read Privacy Policy</Link> </span>
                                <span className="block sm:ml-2 sm:inline-block">
                                    <button type='button' onClick={handleAccept}
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    >Accept</button>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CookieNotice