import React from 'react'
import {
  CloudUploadIcon,
  LockClosedIcon,
  RefreshIcon,
} from '@heroicons/react/outline'
import Container2 from '../../elements/Container/Container2'
const Services = ({ region }) => {
  const uk = {
    title: "Services",
    subTitle: "Web Development & Maintenance Services You Need",
    description: "Ehmiat is your go-to company if you are looking for websites, landing pages, portfolios or web maintenance solutions. We develop error-free websites with creative designs that best portray your business and message to the clients."
  }
  const us = {
    title: "Services",
    subTitle: "Web Development & Maintenance Services You Need",
    description: "Ehmiat is your go-to company if you are looking for websites, landing pages, portfolios or web maintenance solutions. We develop error-free websites with creative designs that best portray your business and message to the clients."
  }
  const uklist = [
    {
      name: 'Landing Page Designs',
      description: 'Landing pages can also be referred to as single-page websites that are alternatives if you are just starting a venture and trying to keep you within a budget.',
      icon: CloudUploadIcon,
      link: 'Landing-Page-Designs'
    },
    {
      name: 'E-Commerce Web Development',
      description: 'E-commerce web development is the process of creating and maintaining an online store or marketplace to sell products or services over the internet.',
      icon: LockClosedIcon,
    },
    {
      name: 'Web Maintenance',
      description: 'Websites require constant runtime changes for mature functioning. That’s why we keep you covered with our monthly maintenance services to keep your websites running smoothly.',
      icon: RefreshIcon,
    },
  ]
  const uslist = [
    {
      name: 'Landing Page Designs',
      description: 'Landing pages can also be referred to as single-page websites that are alternatives if you are just starting a venture and trying to keep you within a budget.',
      icon: CloudUploadIcon,
      link: 'Landing-Page-Designs'
    },
    {
      name: 'E-Commerce Web Development',
      description: 'E-commerce web development is the process of creating and maintaining an online store or marketplace to sell products or services over the internet.',
      icon: LockClosedIcon,
    },
    {
      name: 'Web Maintenance',
      description: 'Websites require constant runtime changes for mature functioning. That’s why we keep you covered with our monthly maintenance services to keep your websites running smoothly.',
      icon: RefreshIcon,
    },
  ]
  return (
    <>
      <Container2
        title={region === 'uk' ? uk.title : us.title}
        subtitle={region === 'uk' ? uk.subTitle : us.subTitle}
        discription={region === 'uk' ? uk.description : us.description}
      >
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {region === 'uk' ? uklist.map((feature) => (
            <div key={feature.name} className="pt-6 ">
              <div className="h-full flow-root bg-gray rounded-lg px-6 pb-8">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center border border-grayborder justify-center p-3 bg-white rounded-md shadow-lg">
                      <feature.icon className="h-6 w-6 text-black " aria-hidden="true" />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                  <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                </div>
              </div>
            </div>
          )) :
            uslist.map((feature) => (
              <div key={feature.name} className="pt-6 ">
                <div className="h-full flow-root bg-gray rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center border border-grayborder justify-center p-3 bg-white rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-black " aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Container2>
    </>
  )
}
export default Services