/* This example requires Tailwind CSS v2.0+ */
export default function PageTitle({title}) {
  return (
    <div className="bg-white border border-gray2">
    <div class=" max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6  pt-6 pb-6 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-xl font-bold leading-7  sm:text-3xl sm:truncate">{title}</h2>
      </div>
    </div>
    </div>
  )
}