import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Container2 from '../../elements/Container/Container2'

const Blogs = ({region}) => {
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    axios.get('https://api.ehmiat.com/wp-json/wp/v2/posts')
      .then(response => {
        setBlogPosts(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  const uk = {title : "Our Blog's"}
  const us = {title : "Our Blog's"}
  // return (
  //   // <>
  //   //   <Container2 
  //   //   subtitle={region === 'uk' ? uk.title : us.title}
  //   //   >
  //   //     <div className="mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
  //   //       {blogPosts.map((post) => (
  //   //         <div key={post.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
  //   //           <div className="flex-shrink-0">
  //   //             <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
  //   //           </div>
  //   //           <div className="flex-1 bg-white p-6 flex flex-col justify-between">
  //   //             <div className="flex-1">
  //   //               {/* <p className="text-sm font-medium text-cyan-600">
  //   //                 <a href={post.category.href} className="hover:underline">
  //   //                   {post.category.name}
  //   //                 </a>
  //   //               </p> */}
  //   //               <a href={post.href} className="block mt-2">
  //   //                 <p className="text-xl font-semibold text-gray-900">{post.title.rendered}</p>
  //   //                 <p className="mt-3 text-base text-gray-500">{post.excerpt.rendered}</p>
  //   //               </a>
  //   //             </div>
  //   //             <div className="mt-6 flex items-center">
  //   //               {/* <div className="flex-shrink-0">
  //   //                 <a href={post.author.href}>
  //   //                   <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
  //   //                 </a>
  //   //               </div> */}
  //   //               <div className="ml-3">
  //   //                 {/* <p className="text-sm font-medium text-gray-900">
  //   //                   <a href={post.author.href} className="hover:underline">
  //   //                     {post.author.name}
  //   //                   </a>
  //   //                 </p> */}
  //   //                 {/* <div className="flex space-x-1 text-sm text-gray-500">
  //   //                   <time 
  //   //                   dateTime={post.datetime}
  //   //                   >
  //   //                     {post.date}</time>
  //   //                   <span aria-hidden="true">&middot;</span>
  //   //                   <span>{post.readingLength} read</span>
  //   //                 </div> */}
  //   //               </div>
  //   //             </div>
  //   //           </div>
  //   //         </div>
  //   //       ))}
  //   //     </div>
  //   //   </Container2>
  //   // </>
  // )
}

export default Blogs