import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function ScrollToTop() {
  const { pathname } = useLocation();
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
    
  //   return () => {
  //     second
  //   }
  // }, [third])
  useEffect(() => {
    setTimeout(() => {
      // do something 1 sec after clicked has changed
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }, 
    // 1000
    );
    // window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}