import { useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import Button from "../../elements/Button/Button";

/* This example requires Tailwind CSS v2.0+ */
export default function Error404({region, geo}) {
  const [title, ] = useState(`404 Page Not Found - Ehmiat ${region}`);
  const [metaDescription, ] = useState(`Ehmiat ${region} - 404 Error Page`);
  const [metaKeywords, ] = useState('');
    return (
      <>
        <Helmet>
                <title>{title}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
            </Helmet>
          <NavBar region={region} geo={geo}/>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <div className="h-screen bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
                  <p className="mt-1 text-base text-gray-500">The page you are looking for could not be found. Please check the URL and try again or contact to the Support.</p>
                </div>
                <div className="mt-10 flex space-x-3 ">
                 <Button name={'Go to home'} link={'/'}/>
                 <Button name={'Contact support'} bg={'white'} link={'/contact'}/>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Footer region={region}/>
      </>
    )
  }