import React from 'react'
import {ExternalLinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
const CTA = ({region}) => {
  const uk = {
    title: "We’re here to help",
    description: "Our customer support will be vigilantly answer your queries, assist you book appointments or help you go through the details of your required services.",
    button: "Connect Today!",
  }
const us = {
    title: "We’re here to help",
    description: "Ehmiat is your go-to company if you are looking for websites, landing pages, portfolios or web maintenance solutions. We develop error-free websites with creative designs that best portray your business and message to the clients.",
    button: "Connect Today!",
  }
  return (
    <>
     {/* CTA Section */}
          <div className="relative bg-white  border border-gray2">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img
                className="w-full h-full object-cover"
                src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                alt=""
              />
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
              />
            </div>
            <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <p className="mt-2 text-black text-3xl font-extrabold tracking-tight sm:text-4xl">{region === 'uk' ? uk.title : us.title}</p>
                <p className="mt-3 text-lg text-gray-300">
                 {region === 'uk' ? uk.description : us.description}
                </p>
                <div className="mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to={`/${region}/contact`}
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                    >
                      {region === 'uk' ? uk.button : uk.button}
                      <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default CTA