import { React, useContext } from 'react'
import About from './Pages/About/About'
import PageDetail from './Pages/Page/PageDetail'
import Contact from './Pages/Contact/Contact'
import Homepage from './Pages/Homepage/HomePage'
import Error404 from './Pages/Error404/Error404'
import { Route, Routes, Navigate } from 'react-router-dom'
import { RegionContext } from './RegionProvider'
import ECommerce from './Pages/Services/ECommerce/ECommerce'
import Maintenance from './Pages/Services/Maintenance/Maintenance'
import LandingPage from './Pages/Services/LandingPage/LandingPage'
// import HomePageUK from './Pages/Homepage/HomePageUK'
// import TPage from './Pages/Page/TPage'

const App = () => {
  const { data } = useContext(RegionContext);
  return (
    <>
      {/* Main Data routes */}
      <div className="bg-white">
        <Routes>
          {/* United States Routes */}
          <Route
            path={`/us`}
            element={<Homepage
              region={'us'}
              geo={data.country_name}
              pageid={'83'}
            />} />
          <Route
            path={`/us/landing-page-design`}
            element={<LandingPage
              region={'us'}
              geo={data.country_name}
              pageid={'85'}
            />} />
          <Route
            path={`/us/ecommerce-web-design`}
            element={<ECommerce
              region={'us'}
              geo={data.country_name}
              pageid={'87'} />}
          />
          <Route
            path={`/us/website-maintenance`}
            element={<Maintenance
              region={'us'}
              geo={data.country_name}
              pageid={'89'} />}
          />
          <Route
            path={`/us/about`}
            element={<About
              region={'us'}
              geo={data.country_name}
              pageid={'93'} />}
          />
          <Route
            path={`/us/contact`}
            element={<Contact
              region={'us'} 
              pageid={'91'}
              />} />
          {/* Dynamic Pages */}
          <Route
            path={`/us/privacy-policy`}
            element={<PageDetail
              region={'us'} 
              geo={data.country_name}
              pageid={'66'} />}
          />
          <Route
            path={`/us/terms-and-conditions`}
            element={<PageDetail
              region={'us'} 
              geo={data.country_name}
              pageid={'68'} />}
          />
          {/* <Route
            path={`/us/careers`}
            element={<TPage
              region={'us'} 
              geo={data.country_name}
              pageid={'102'} />}
          /> */}
          {/* Dymaic Pages End */}
          <Route
            path={`/us/*`}
            element={<Error404
              region={'us'}
              geo={data.country_name} />}
          />
          {/* End United States Routes */}
          {/* United Kingdom Routes */}
          {/* <Route
            path={`/uk`}
            element={<HomePageUK
              region={'uk'}
              geo={data.country_name}
              pageid={'84'} />}
          />
          <Route
            path={`/uk/landing-page-design`}
            element={<LandingPage
              region={'uk'}
              geo={data.country_name}
              pageid={'86'} />}
          />
          <Route
            path={`/uk/ecommerce-web-design`}
            element={<ECommerce
              region={'uk'}
              geo={data.country_name}
              pageid={'88'} />}
          />
          <Route
            path={`/uk/website-maintenance`}
            element={<Maintenance
              region={'uk'}
              geo={data.country_name}
              pageid={'90'} />}
          />
          <Route
            path={`/uk/about`}
            element={<About
              region={'uk'}
              geo={data.country_name}
              pageid={'94'} />}
          />
          <Route
            path={`/uk/contact`}
            element={<Contact
              region={'uk'}
              geo={data.country_name}
              pageid={'92'} />}
          />
          <Route
            path={`/uk/privacy-policy`}
            element={<PageDetail
              region={'uk'} 
              geo={data.country_name}
              pageid={'65'} />}
          />
          <Route
            path={`/uk/terms-and-conditions`}
            element={<PageDetail
              region={'uk'} 
              geo={data.country_name}
              pageid={'69'} />}
          />
          <Route
            path={`/uk/*`}
            element={<Error404
              region={'uk'} geo={data} />} /> */}
          {/* End United Kngdom Routes */}
          {/* Old Url Redirect */}
          <Route path='/contactus' element={<Navigate to='/us/contact' />} />
          <Route path='/hire-developer' element={<Navigate to='/us/contact' />} />
          <Route path='/blog' element={<Navigate to='/us/' />} />
          <Route path='/services/maintenance' element={<Navigate to='/us/maintenance' />} />
          <Route path='services/wordpress' element={<Navigate to='/us/landing-page-design' />} />
           {/* dynamic redirect */}
          {/* Defult Redirects */}
          <Route path='/' element={<Navigate to='/us' />} />
          <Route path='*' element={<Navigate to='/us' />} />
        </Routes>
      </div>
    </>
  )
}
export default App




/* United States Routes */
/* <Route 
path={`/us`} 
element={<Homepage />} />
<Route 
path={`/us/wordpress`} element={<WordPress />} />
<Route 
path={`/us/about`} element={<About />} />
<Route 
path={`/us/contact`} element={<Contact />} />
<Route 
path={`/us/privacy-policy`} element={<PageDetail />} />
<Route 
path={`/us/*`} element={<Error404 />} /> */
/* End United States Routes */