import React from 'react'
import Button from '../../elements/Button/Button'
const HeroSection = ({region}) => {
  const uk = 
      {
        title: 'Elevating Your Online Presence',
        description: 'Specialized Web Solutions for individuals, businesses & brands to attract, interact and convert more and more leads into sales.',
        button: 'Book Free Consultation',
        link: 'contact',
        featureImage: 'https://images.unsplash.com/photo-1486299267070-83823f5448dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80',
      }
  const us = 
      {
        title: 'Elevating Your Online Presence',
        description: 'Specialized Web Solutions for individuals, businesses & brands to attract, interact and convert more and more leads into sales.',
        button: 'Book Free Consultation',
        link: 'contact',
        featureImage: 'https://images.unsplash.com/photo-1522083165195-3424ed129620?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2197&q=80',
      }
  return (
    <>
      <div className="pt-10 bg-gray sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
              <div className="lg:py-24">
                <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span className="pb-3 block text-black sm:pb-5">
                  {region === 'uk' ? uk.title : us.title}
                  </span>
                </h1>
                <p className="text-base text-off-white sm:text-xl lg:text-lg xl:text-xl">
                  <p>{us.description}</p>
                </p>

                <div className="mt-10 sm:mt-12">
                  <Button
                  name={region === 'uk' ? uk.button : us.button}
                  link={`/${region}/${region === 'uk' ? uk.link : us.link}`}
                  />
                </div>
              </div>
            </div>
            <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                <img
                  className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={region === 'uk' ? uk.featureImage : us.featureImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection