import axios from 'axios'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
const SEO = ({ region, pageid }) => {
    const [lang, setlang] = useState('en_US');
    const [title, setTitle] = useState('');
    const [description, setdescription] = useState('');
    const [url, seturl] = useState('');
    useEffect(() => {
        axios.get(`https://api.ehmiat.com/wp-json/${region}/pages/${pageid}`)
            .then(res => {
                setlang(`${region === 'uk' ? "en_GB" : "en_US"}`)
                setTitle(res.data.yoast_head_json.title);
                setdescription(res.data.yoast_head_json.description);
                seturl(res.data.slug);

            })
            .catch(err => console.log(err))
    }, [pageid, region])
    return (
        <Helmet>
            <html lang={lang}></html>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="robots" content="index, follow" />
            <meta name="robots" content="max-snippet:-1" />
            <meta name="robots" content="max-image-preview:large" />
            <meta name="robots" content="max-video-preview:-1" />
            <meta property="og:locale" content={lang} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description} />
            <meta property="og:url" content={`https://www.ehmiat.com/${region}/${url}`} />
            <meta property="og:site_name" content="Ehmiat" />
            <meta property="article:modified_time" content="2023-01-27T22:06:58+00:00" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

    )
}

export default SEO