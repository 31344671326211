import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import Container2 from '../../elements/Container/Container2'
import { Link } from 'react-router-dom'
const uk = {
    title: "Packages",
    subTitle: "Web Development Packages",
    description: "Customized package options to fit your specific needs and budget, including planning, design, development, testing, and deployment."
}
const us = {
    title: "Services",
    subTitle: "Web Development & Maintenance Services You Need",
    description: "Ehmiat is your go-to company if you are looking for websites, landing pages, portfolios or web maintenance solutions. We develop error-free websites with creative designs that best portray your business and message to the clients."
}
const ukplans = [
    {
        title: 'Landing Page',
        featured: false,
        description: 'All your essential business finances, taken care of.',
        price: 129,
        link: 'landing-page-design',
        mainFeatures: [
            { id: 1, value: 'Fresh Content' },
            { id: 2, value: 'Industry Research' },
            { id: 3, value: 'Clean, Responsive Design' },
            { id: 4, value: 'Mobile Friendly' },
            { id: 5, value: 'Optimized for SEO' },
            { id: 6, value: 'Gear for Conversion' },
            { id: 7, value: '3 Day Turnaround' }
        ],
    },
    {
        title: 'ECommerce',
        featured: true,
        description: 'The best financial services for your thriving business.',
        price: 349,
        link: 'ecommerce-web-design',
        mainFeatures: [
            { id: 1, value: 'Main Pages' },
            { id: 2, value: 'Product Pages' },
            { id: 3, value: 'Perfect for Businesses' },
            { id: 4, value: 'Theme Based Design' },
            { id: 5, value: 'Admin Dashboard Area' },
            { id: 6, value: 'User Dashboard Area' },
            { id: 7, value: 'Payment Integration' },
            { id: 8, value: 'Content Management System' },
            { id: 9, value: 'Original Content' },
            { id: 10, value: 'Multi-Currency' },
            { id: 11, value: 'Discount Coupon Code Module' },
            { id: 12, value: 'Sliders' },
            { id: 13, value: 'Responsive Design' },
            { id: 14, value: 'Social Media Integration' },
            { id: 15, value: 'SEO Features' },
            { id: 16, value: 'Blog Setup' },
            { id: 17, value: 'Contact Forms Included' },
            { id: 18, value: 'Multiple Revisions' }
        ],
    },
    {
        title: 'Maintenance',
        featured: false,
        description: 'Convenient features to take your business to the next level.',
        monthly: true,
        price: 80,
        link: 'website-maintenance',
        mainFeatures: [
            { id: 1, value: 'Upto 12 Support Hours' },
            { id: 2, value: 'Free Task Manager Access' },
            { id: 3, value: '0-3 days Turnaround Time' },
            { id: 4, value: 'Image Edits and Uploading to website' },
            { id: 5, value: 'Existing Content Modifications' },
            { id: 6, value: 'Adding, Removing & Updating website links' },
            { id: 7, value: 'SSL Renewal Check' },
            { id: 8, value: 'Domain Renewal Check' },
            { id: 9, value: 'HTML Sitemap Creation & Uploading' },
            { id: 10, value: 'Website Speed Optimization' },
            { id: 11, value: 'Email/Domain/Hosting Support' },
            { id: 12, value: '3rd Party Web Hosting Help' },
            { id: 13, value: 'Daily Backup' },
            { id: 14, value: 'Consulting Requests' },
            { id: 15, value: 'Form Creation' },
            { id: 16, value: 'PHP Scripting Help' },
            { id: 17, value: 'CMS / WordPress Support' },
            { id: 18, value: 'Server Support' },
            { id: 19, value: 'E-commerce Site Support' },
            { id: 20, value: 'On-Site SEO' },
            { id: 21, value: 'Plugins and Theme Version Update' },
            { id: 22, value: 'Extension Installation & Modification' },
        ],
    },
]
const usplans = [
    {
        title: 'Landing Page',
        featured: false,
        description: 'All your essential business finances, taken care of.',
        price: 149,
        link: 'landing-page-design',
        mainFeatures: [
            { id: 1, value: 'Fresh Content' },
            { id: 2, value: 'Industry Research' },
            { id: 3, value: 'Clean, Responsive Design' },
            { id: 4, value: 'Mobile Friendly' },
            { id: 5, value: 'Optimized for SEO' },
            { id: 6, value: 'Gear for Conversion' },
            { id: 7, value: '3 Day Turnaround' }
        ],
    },
    {
        title: 'ECommerce',
        featured: true,
        description: 'The best financial services for your thriving business.',
        price: 499,
        link: 'ecommerce-web-design',
        mainFeatures: [
            { id: 1, value: 'Main Pages' },
            { id: 2, value: 'Product Pages' },
            { id: 3, value: 'Perfect for Businesses' },
            { id: 4, value: 'Theme Based Design' },
            { id: 5, value: 'Admin Dashboard Area' },
            { id: 6, value: 'User Dashboard Area' },
            { id: 7, value: 'Payment Integration' },
            { id: 8, value: 'Content Management System' },
            { id: 9, value: 'Original Content' },
            { id: 10, value: 'Multi-Currency' },
            { id: 11, value: 'Discount Coupon Code Module' },
            { id: 12, value: 'Sliders' },
            { id: 13, value: 'Responsive Design' },
            { id: 14, value: 'Social Media Integration' },
            { id: 15, value: 'SEO Features' },
            { id: 16, value: 'Blog Setup' },
            { id: 17, value: 'Contact Forms Included' },
            { id: 18, value: 'Multiple Revisions' }
        ],
    },
    {
        title: 'Maintenance',
        featured: false,
        description: 'Convenient features to take your business to the next level.',
        monthly: true,
        price: 100,
        link: 'website-maintenance',
        mainFeatures: [
            { id: 1, value: 'Upto 12 Support Hours' },
            { id: 2, value: 'Free Task Manager Access' },
            { id: 3, value: '0-3 days Turnaround Time' },
            { id: 4, value: 'Image Edits and Uploading to website' },
            { id: 5, value: 'Existing Content Modifications' },
            { id: 6, value: 'Adding, Removing & Updating website links' },
            { id: 7, value: 'SSL Renewal Check' },
            { id: 8, value: 'Domain Renewal Check' },
            { id: 9, value: 'HTML Sitemap Creation & Uploading' },
            { id: 10, value: 'Website Speed Optimization' },
            { id: 11, value: 'Email/Domain/Hosting Support' },
            { id: 12, value: '3rd Party Web Hosting Help' },
            { id: 13, value: 'Daily Backup' },
            { id: 14, value: 'Consulting Requests' },
            { id: 15, value: 'Form Creation' },
            { id: 16, value: 'PHP Scripting Help' },
            { id: 17, value: 'CMS / WordPress Support' },
            { id: 18, value: 'Server Support' },
            { id: 19, value: 'E-commerce Site Support' },
            { id: 20, value: 'On-Site SEO' },
            { id: 21, value: 'Plugins and Theme Version Update' },
            { id: 22, value: 'Extension Installation & Modification' },
        ],
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Packages = ({ region }) => {
    return (
        <>
            <Container2
                bg={'gray'}
                title={region === 'uk' ? uk.titel : us.title}
                subtitle={region === 'uk' ? uk.subTitle : us.subTitle}
                discription={region === 'uk' ? uk.description : us.description}
            >
                <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-12 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
                    {/* Decorative background */}
                    <div
                        aria-hidden="true"
                        className="hidden absolute top-4 bottom-6 left-8 right-8 inset-0 bg-indigo-700 rounded-tl-lg rounded-tr-lg lg:block"
                    />
                    <div className="relative space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3">
                        {region === "uk" ? ukplans.map((plan) => (
                            <div
                                key={plan.title}
                                className={classNames(
                                    plan.featured ? 'bg-black ring-2 ring-gray2 shadow-md' : 'bg-white lg:bg-transparent',
                                    'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12'
                                )}
                            >
                                <div>
                                    <h3
                                        className={classNames(
                                            plan.featured ? 'text-gray' : 'text-black',
                                            'text-sm font-semibold uppercase tracking-wide'
                                        )}
                                    >
                                        {plan.title}
                                    </h3>
                                    <div className="flex flex-col  sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                                        <div className="mt-3 flex items-center justify-items-center">
                                            <p
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'text-2xl font-extrabold tracking-tight'
                                                )}
                                            >
                                                {/* {region === "uk" ? "£" : "$"}{plan.price} */} Start
                                            </p>
                                            <div className="ml-4">
                                                <p className={classNames(plan.featured ? 'text-gray' : 'text-black', 'text-4xl')}>
                                                    {region === "uk" ? "£" : "$"}{plan.price}{plan.monthly ? "/mo" : null}
                                                </p>
                                                {/* <p className={classNames(plan.featured ? 'text-gray' : 'text-black', 'text-sm')}>
                                                    Billed yearly ({region === "uk" ? "£" : "$"}{plan.priceYearly})
                                                </p> */}
                                            </div>
                                        </div>
                                        <Link
                                            to={plan.link}
                                            className={classNames(
                                                plan.featured
                                                    ? 'bg-gray text-black '
                                                    : 'bg-white text-black',
                                                'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full'
                                            )}
                                        >
                                            Explore {plan.title}
                                        </Link>
                                    </div>
                                </div>
                                <h4 className="sr-only">Features</h4>
                                <ul

                                    className={classNames(
                                        plan.featured
                                            ? 'border-gray divide-gray2'
                                            : 'border-black divide-black divide-opacity-75',
                                        'mt-7 border-t divide-y lg:border-t-0'
                                    )}
                                >
                                    {plan.mainFeatures.map((mainFeature) => (
                                        <li key={mainFeature.id} className="py-3 flex items-center">
                                            <CheckIcon
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'w-5 h-5 flex-shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'ml-4 text-sm font-medium'
                                                )}
                                            >
                                                {mainFeature.value}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )) : usplans.map((plan) => (
                            <div
                                key={plan.title}
                                className={classNames(
                                    plan.featured ? 'bg-black ring-2 ring-gray2 shadow-md' : 'bg-white lg:bg-transparent',
                                    'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12'
                                )}
                            >
                                <div>
                                    <h3
                                        className={classNames(
                                            plan.featured ? 'text-gray' : 'text-black',
                                            'text-sm font-semibold uppercase tracking-wide'
                                        )}
                                    >
                                        {plan.title}
                                    </h3>
                                    <div className="flex flex-col  sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                                        <div className="mt-3 flex items-center justify-items-center">
                                            <p
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'text-2xl font-extrabold tracking-tight'
                                                )}
                                            >
                                                {/* {region === "uk" ? "£" : "$"}{plan.price} */} Start
                                            </p>
                                            <div className="ml-4">
                                                <p className={classNames(plan.featured ? 'text-gray' : 'text-black', 'text-4xl')}>
                                                    {region === "uk" ? "£" : "$"}{plan.price}{plan.monthly ? "/mo" : null}
                                                </p>
                                                {/* <p className={classNames(plan.featured ? 'text-gray' : 'text-black', 'text-sm')}>
                                                Billed yearly ({region === "uk" ? "£" : "$"}{plan.priceYearly})
                                            </p> */}
                                            </div>
                                        </div>
                                        <Link
                                            to={plan.link}
                                            className={classNames(
                                                plan.featured
                                                    ? 'bg-gray text-black '
                                                    : 'bg-white text-black',
                                                'mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full'
                                            )}
                                        >
                                            Explore {plan.title}
                                        </Link>
                                    </div>
                                </div>
                                <h4 className="sr-only">Features</h4>
                                <ul

                                    className={classNames(
                                        plan.featured
                                            ? 'border-gray divide-gray2'
                                            : 'border-black divide-black divide-opacity-75',
                                        'mt-7 border-t divide-y lg:border-t-0'
                                    )}
                                >
                                    {plan.mainFeatures.map((mainFeature) => (
                                        <li key={mainFeature.id} className="py-3 flex items-center">
                                            <CheckIcon
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'w-5 h-5 flex-shrink-0'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    plan.featured ? 'text-gray' : 'text-black',
                                                    'ml-4 text-sm font-medium'
                                                )}
                                            >
                                                {mainFeature.value}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </Container2>
        </>
    )
}

export default Packages