
  
  export default function FAQS({region}) {
    const uk = {
      title: 'Frequently asked questions',

    }
    const us = {
      title: 'Frequently asked questions',

    }
    const ukfaqs = [
      {
        id: 1,
        question: "Why do I have to pay if WordPress is a free platform?",
        answer:
          "WordPress itself is a CMS (Content Management System) that allows you to build your own customized websites. Professional WordPress Web Designers are expert at creating websites as they have a sound grip on all the tools within them.",
      },
      {
        id: 2,
        question: "How much will an e-commerce website ​​​​​cost me?",
        answer:
          "At Ehmiat, our website development and design services starts from as low as £349 only. The price may vary according to the project and customizations you need.​",
      },
      {
        id: 3,
        question: "Why I need monthly website maintenance services?",
        answer:
          "Websites require regular recovery of data, optimization, bug removal and upgrades, which will be taken care of by the best developers at Ehmiat making your website error-free.",
      },
      {
        id: 4,
        question: "What services are included in web maintenance services?",
        answer:
          "Our web maintenance services include back-up recovery, bug removal, speed optimization, mobile optimization, tracking and analytics, accessibility and much more. Please click the link for detailed information about the web maintenance package. ",
      },
      {
        id: 5,
        question: "How can I connect with the Ehmiat team?",
        answer:
          "You can mail us on official emails, social platforms or you may fill out the form, so our team will contact you on your given email.",
      },
    ]
    const usfaqs = [
      {
        id: 1,
        question: "Why do I have to pay if WordPress is a free platform?",
        answer:
          "WordPress itself is a CMS (Content Management System) that allows you to build your own customized websites. Professional WordPress Web Designers are expert at creating websites as they have a sound grip on all the tools within them.",
      },
      {
        id: 2,
        question: "How much will an e-commerce website ​​​​​cost me?",
        answer:
          "At Ehmiat, our website development and design services starts from as low as $499 only. The price may vary according to the project and customizations you need.​",
      },
      {
        id: 3,
        question: "Why I need monthly website maintenance services?",
        answer:
          "Websites require regular recovery of data, optimization, bug removal and upgrades, which will be taken care of by the best developers at Ehmiat making your website error-free.",
      },
      {
        id: 4,
        question: "What services are included in web maintenance services?",
        answer:
          "Our web maintenance services include back-up recovery, bug removal, speed optimization, mobile optimization, tracking and analytics, accessibility and much more. Please click the link for detailed information about the web maintenance package. ",
      },
    ]

    return (
      <div className="bg-gray">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="lg:max-w-2xl lg:mx-auto lg:text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-black sm:text-4xl">{region === 'uk' ? uk.title : us.title}</h2>
            {/* <p className="mt-4 text-gray-400">
              Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
              Urna, sed a lectus elementum blandit et.
            </p> */}
          </div>
          <div className="mt-20">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
             {region === 'uk' ? 
              ukfaqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="font-semibold text-black">{faq.question}</dt>
                  <dd className="mt-3 text-black">{faq.answer}</dd>
                </div>
              )) :  usfaqs.map((faq) => (
                <div key={faq.id}>
                  <dt className="font-semibold text-black">{faq.question}</dt>
                  <dd className="mt-3 text-black">{faq.answer}</dd>
                </div>
              )) }
            </dl>
          </div>
        </div>
      </div>
    )
  }