import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({name, bg, link, type}) => {
    return (
        <Link
            type={type}
            to={link}
            className={`py-3 px-4 rounded-md ${'bg-' + bg || 'bg-black'} bg-black text-white font-medium `}
        >
            {name || 'null' }
        </Link>
    )
}

export default Button