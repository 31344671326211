import { useState, useEffect, createContext } from 'react';
import axios from 'axios';
export const RegionContext = createContext();
const RegionProvider = ({children}) => {
    const [region, setRegion] = useState();
    const [ data ,setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            let userLocation = getUserLocationFromCookie();

            if (!userLocation) {
                const { data } = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=7952c4c8f99d444cb7537c79d67aaacb');
                setUserLocationCookie(data.country_code2);
                userLocation = data.country_code2;
            }
            if (userLocation === 'GB') {
                const { data } = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=7952c4c8f99d444cb7537c79d67aaacb');
                setRegion('uk');
                setData(data);
            } 
            // else if (userLocation === 'CA') {
            //     const { data } = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=7952c4c8f99d444cb7537c79d67aaacb');
            //     setRegion('ca');
            //     setData(data);
            // } 
            else {
                const { data } = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=7952c4c8f99d444cb7537c79d67aaacb');
                setRegion('us');
                setData(data);
            }
        };

        fetchData();
    }, []);

    function setUserLocationCookie(region) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        document.cookie = `geo=${region}; expires=${expirationDate.toUTCString()}; path=/`;
    }

    function getUserLocationFromCookie() {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('geo=')) {
                return cookie.substring(13);
            }
        }
        return null;
    } 

    return (
        <RegionContext.Provider value={{region: region, data: data}}>
        {/* <div>
            <h1>Our Website is Not Availibe {data.country_name}
                Your are now viewing {region} Region Webiste.</h1>
            <p>Data: {JSON.stringify(data)}</p> */}
            {children}
            {/* </div> */}
        </RegionContext.Provider>
    );
}

export default RegionProvider;