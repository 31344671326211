/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const landingpage = [
    "Fresh Content",
    "Industry Research",
    "Clean, Responsive Design",
    "Mobile Friendly",
    "Optimized for SEO",
    "Gear for Conversion",
    "3 Day Turnaround",
    
]
const ecommerce = [
    "Main Pages",
    "Product Pages",
    "Perfect for Businesses",
    "Theme Based Design",
    "Admin Dashboard Area",
    "User Dashboard Area",
    "Payment Integration",
    "Content Management System",
    "Original Content",
    "Multi-Currency",
    "Discount Coupon Code Module",
    "Sliders",
    "Responsive Design",
    "Social Media Integration",
    "SEO Features",
    "Blog Setup",
    "Contact Forms Included",
    "Multiple Revisions",
]
const maintenance = [
    "Upto 12 Support Hours",
    "Free Task Manager Access",
    "0-3 days Turnaround Time",
    "Image Edits and Uploading to website",
    "Existing Content Modifications",
    "Adding, Removing & Updating website links",
    "SSL Renewal Check",
    "Domain Renewal Check",
    "HTML Sitemap Creation & Uploading",
    "Website Speed Optimization",
    "Email/Domain/Hosting Support",
    "3rd Party Web Hosting Help",
    "Daily Backup",
    "Consulting Requests",
    "Form Creation",
    "PHP Scripting Help",
    "CMS / WordPress Support",
    "Server Support",
    "E-commerce Site Support",
    "On-Site SEO",
    "Plugins and Theme Version Update",
    "Extension Installation & Modification",
]

export default function Price({region, title, description, page,geo }) {
  return (
    <>
    {geo === 'Pakistan' ? null :
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Simple no-tricks pricing</h2>
            <p className="mt-4 text-xl text-gray-600">
              If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.
            </p>
          </div> */}
        </div>
      </div>
      <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-100" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">{title}</h3>
                <p className="mt-6 text-base text-gray-500">
                  {description}
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    {page === 'landingpage' ?
                    landingpage.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))
                    :
                    page === 'ecommerce' ? 
                    ecommerce.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))
                    :
                    page === 'maintenance' ? 
                    maintenance.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))
                    : null
                    }
                  </ul>
                </div>
              </div>
              <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                {/* <p className="text-lg leading-6 font-medium text-gray-900">Pay once, own it forever</p> */}
                <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                  {page === 'landingpage' ? 
                  <span>{region === "uk" ? "£" : "$" }{region === 'uk' ? '129' : '149'}</span>
                  : page === 'ecommerce' ? 
                                    <span>{region === "uk" ? "£" : "$" }{region === 'uk' ? '349' : '499'}</span>
                  : page === 'maintenance' ? 
                                    <span>{region === "uk" ? "£" : "$" }{region === 'uk' ? '80' : '100'} </span>
                                    : null
                }
                  <span className="ml-3 text-xl font-medium text-gray-500">{region === "uk" ? "GBP" : "USD" } {page === 'maintenance' ? <p>{' '}/month</p> : null}</span>
                </div>
                {/* <p className="mt-4 text-sm">
                  <a href="#1" className="font-medium text-gray-500 underline">
                    Learn about our membership policy
                  </a>
                </p> */}
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <Link
                      to={`/${region}/contact`}
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-black"
                    >
                      Get Now
                    </Link>
                  </div>
                </div>
                {/* <div className="mt-4 text-sm">
                  <a href="#1" className="font-medium text-gray-900">
                    Get a free sample <span className="font-normal text-gray-500">(20MB)</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}
    </>
  )
}